import "./watchHistory.scss";
import { useContext, useEffect } from "react";
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { DarkModeContext } from "../../context/darkModeContext";
import { AnimeContext } from "../../context/animeContent";
import Card from "../../components/Card/Card";
import Header from "../../components/Header/Header";

function WatchHistory() {
  const {darkMode, toggle} = useContext(DarkModeContext);
  const {watchHistory} = useContext(AnimeContext);
  console.log(watchHistory);

  return (
    <div className="watchHistory">
      <Header HIcon={AccessTimeFilledRoundedIcon} title = 'Watch History'/>
        <div className="cardContainer">
          {watchHistory ? watchHistory.map((anime)=>(
            <Card key={anime.id} anime={anime} />
          )) : (
            <p className="watchText">You have not started to see any anime yet :(</p>
          )}
        </div>
    </div>
  )
}

export default WatchHistory