import "./header.scss";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { useContext, useEffect, useState } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';

function Header({HIcon, title}) {
  const {darkMode, toggle} = useContext(DarkModeContext);

  return (
    <div className="headertitle">
        <h1><HIcon className="iconName" /> {title}</h1>
        <div className="mobileIcon">
        <Link to='/'>
        <img src={require("../../assets/logo.png")} alt="" />
        </Link>
        </div>
        <div className="switch">
          <Switch uncheckedIcon={<DarkModeRoundedIcon style={{width: "2.3rem", height: '1rem', marginTop: '.3rem'}} />} checkedIcon={<WbSunnyRoundedIcon style={{width: "1rem", marginLeft: "6px", marginTop: '.05rem'}} />} onChange={toggle} height={25} width={55}  className="switch"  onColor="#fbae1f" checked={darkMode} /> 
        </div>
    </div>
  )
}

export default Header