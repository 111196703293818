import { useEffect, useRef } from 'react';
import Artplayer from 'artplayer';
import Hls from "hls.js";
export default function Player({ option, getInstance, ...rest }) {
    const artRef = useRef();
    useEffect(() => {
        const art = new Artplayer({
            ...option,
            container: artRef.current,
            customType: {
                m3u8: function (video, url) {
                  if (Hls.isSupported()) {
                    const hls = new Hls();
                    hls.loadSource(url);
                    hls.attachMedia(video);
                  } else {
                    const canPlay = video.canPlayType("application/vnd.apple.mpegurl");
                    if (canPlay === "probably" || canPlay === "maybe") {
                      video.src = url;
                    }
                  }
                },
              }
        });
        if (getInstance && typeof getInstance === 'function') {
            getInstance(art);
        }
        return () => {
            if (art && art.destroy) {
                art.destroy(false);
            }
        };
    }, []);
    return <div ref={artRef} {...rest}></div>;
}