import Home from "./Pages/Home/Home";
import "./app.scss";
import RightBar from "./components/Rightbar/Rightbar";
import LeftBar from "./components/Leftbar/Leftbar";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext"
import Anime from "./Pages/Anime/Anime";
import Trending from "./Pages/Trending/Trending";
import Movies from "./Pages/Movies/Movies";
import WatchHistory from "./Pages/WatchHistory/WatchHistory";
import Search from "./Pages/Search/Search";

function App() {

  const {darkMode} = useContext(DarkModeContext);

  const Layout = () =>{
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        <div style={{display: "flex"}}>
          <LeftBar />
          <div style={{flex: 6}}>
            <Outlet />
          </div>
          <RightBar />
        </div>
      </div>
    )
  }

   const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout />
      ),
      children:[
        {
          path: "/",
          element : <Home />
        },
        {
          path: "/trending",
          element : <Trending />
        },
        {
          path: "/movies",
          element : <Movies />
        },
        {
          path: "/history",
          element : <WatchHistory />
        },
        {
          path: "/search",
          element : <Search />
        },
        {
          path: "/anime/:id",
          element: <Anime />
        }
        
      ]
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
 
}

export default App;
