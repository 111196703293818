import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Switch from "react-switch";
import moment from 'moment';
import SmartDisplayRoundedIcon from '@mui/icons-material/SmartDisplayRounded';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import './anime.scss';
import { AnimeContext } from '../../context/animeContent';
import ClipLoader from "react-spinners/ClipLoader";
import EpisodeCard from '../../components/EpisodeCard/EpisodeCard';
import Artplayer from '../../player/Player';
import parser from "html-react-parser";
import Header from '../../components/Header/Header';


function Anime() {
  const { id } = useParams();
  const {AnimeInfo, fetchAnimeInfo, setwatchHistoryData, fetchAnimeWatchLink, dubSub, setDubSub} = useContext(AnimeContext);
  const [loader, setLoader] = useState(false);
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [currentEpisodeTitle, setcurrentEpisodeTitle] = useState(null);

  useEffect(()=>{
    setLoader(false)
    async function animeData(){
        await fetchAnimeInfo(id, dubSub);
        setLoader(true)
    }
    animeData();
  }, [id])

  const hanldeSubDub = async () =>{
    setLoader(false);
    await fetchAnimeInfo(id, !dubSub);
    console.log();
    await setDubSub(!dubSub);
    setLoader(true);
  }

  const handleEpisode = async (id, title) =>{
    setLoader(false);
    const data = await fetchAnimeWatchLink(id);
    setwatchHistoryData(AnimeInfo);
    setCurrentEpisode(data);
    setcurrentEpisodeTitle(title);
    setLoader(true);
  }

  const getEpisodeQuality = (src) =>{
        let epQuality = [];
        let te = 0;
        src.forEach(element => {
            let curObject = {
                html: element.quality,
                url: `https://proxy.vnxservers.com/proxy/m3u8/${element.url}`,
            }
            if(te == 0){
                curObject.default = true;
            }
            epQuality.push(curObject)
            te++;
        });
        return epQuality;

    }


  return (
    <div className='Anime'>
        { loader ? (
            <div>
                <Header HIcon={OndemandVideoRoundedIcon} title = {AnimeInfo?.title?.english}/>

                <main className="mainContent">
                <div>
                    {currentEpisode ? <Artplayer
                        option={{
                            url: `https://proxy.vnxservers.com/proxy/m3u8/${currentEpisode[0].url}`,
                            fullscreen: true,
                            miniProgressBar: true,
                            autoOrientation: true,
                            quality: [getEpisodeQuality(currentEpisode)],
                            layers: [
                                {
                                  name: "title",
                                  html: currentEpisodeTitle,
                                  style: {
                                    position: "absolute",
                                    top: "10px",
                                    left: "10px",
                                    justifySelf: "center",
                                    alignSelf: "center",
                                    opacity: "1.0",
                                    padding: "12px 20px",
                                    borderRadius: "8px",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    fontWeight: "bold",
                                    zIndex: 100,
                                    transition: "0.3s all ease",
                                  },
                                },
                              ],
                        }}
                        style={{
                            width: '100%',
                            height: window.innerWidth > 600 ? '450px' : '230px',
                        }}
                    /> : (
                        <div className='coverPic' style={{background: `url(${AnimeInfo.cover})`}}>
                            <div className="overlay"></div>
                            <div className="coverCard">
                               <div>
                                <img src={AnimeInfo.image} alt={AnimeInfo?.title?.english} />
                               </div>
                               <div className='desc'>
                                <p>{AnimeInfo?.title?.english}</p>
                                {parser(AnimeInfo?.description?.substr(0, 450))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                    
                    <div className="sectitle_cont">
                        <div  className='sectitle'><SmartDisplayRoundedIcon /> Episode List</div>
                        {AnimeInfo.nextAiringEpisode && <div className="nextEpTime">Next Episode Release : {moment.unix(AnimeInfo.nextAiringEpisode.airingTime).format("HH:MM A - DD-MM-YYYY")}</div>}
                        <div className="dubSub">
                            Sub <Switch onChange={hanldeSubDub} height={18} width={40}  className="switch"  onColor="#fbae1f" checked={dubSub} /> Dub
                        </div>
                    </div>
                    <div className="episodeCont">
                    {
                        AnimeInfo?.episodes.reverse().map((episode)=>(
                            <EpisodeCard key={episode.id} ep={episode} handleEpisode={handleEpisode} />
                        ))
                    }
                    </div>
                </main>
            </div>
        ) : (
            <div className="loader">
                <ClipLoader color="#fbae1f" size={50} />
            </div>
        )}
    </div>
  )
}

export default Anime