import { Link } from "react-router-dom";
import "./card.scss";


function Card({anime}) {
  return (
    <Link to={`/anime/${anime.id}`} style={{textDecoration: "none"}} className="cardContainerLink">
      <div className="Card">
          <div className="imgContainer">
              <img src={anime.image} alt=""/>
          </div>
          <div className="titleContainer">
              <p className="cardTitle">{anime.title.english}</p>
              <p className="rating">Rating: {anime.rating}</p>
          </div>
      </div>
    </Link>
  )
}

export default Card