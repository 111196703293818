import "./episodeCard.scss";

function EpisodeCard({ep, handleEpisode}) {

  let title = `EP - ${ep.number}`;

  if(ep.title){
    title += `- ${ep.title}`;
  }
  return (
    <div className='episodeCard' onClick={() => handleEpisode(ep.id, title)}>
        <div className="episodeImg">
            { ep.image && <img src={ep.image} /> }
        </div>
        <div className="contentCont">
            <h6>{title}</h6>
            <p>{ep?.description?.substring(0, 145)}</p>
        </div>
    </div>
  )
}

export default EpisodeCard