import './detailCard.scss'

function DetailCard({anime}) {
  return (
    <div className='DetailCard'>
        <div className="imgContainer">
            <img src={anime.image} alt="" />
        </div>
        <div className="textContent">
            <p className="title">{anime.title.english}</p>
            <p className='desc'>{anime.description.substring(0, 180)}...</p>
        </div>
    </div>
  )
}

export default DetailCard