import "./righbar.scss";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import WhatshotRoundedIcon from '@mui/icons-material/WhatshotRounded';
import { useContext, useEffect } from "react";
import { AnimeContext } from "../../context/animeContent";
import DetailCard from "../DetailCard/DetailCard";
import { Link, useNavigate, useLocation } from "react-router-dom";

function Rightbar() {
  const {popularContent, fetchPopular, handleSearch, searchTerm} = useContext(AnimeContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(()=>{
    async function rightBarData(){
      await fetchPopular();
    }
    rightBarData();
  }, [])

  const handleSearchInput = (e) =>{
    handleSearch(e.target.value)
  }

  const hanldeSearchRoute = (e) =>{
    e.preventDefault();
    if(searchTerm && location.pathname != "/search"){
      navigate("/search")
    }
  }
  return (
    <div className="rightbar">
        <form onSubmit={hanldeSearchRoute} >
          <div className="search">
            <input type="text" className="textInput" onChange={handleSearchInput} value={searchTerm} placeholder="Search Here..." />
            <SearchRoundedIcon className='SearchIcon' onClick={hanldeSearchRoute} />
          </div>
        </form>

      <div className="popular">
        <div className="popularTitle">
            <WhatshotRoundedIcon />
            <p>Popular Anime</p>
            
        </div>
        <div className="animeContainer">
              {popularContent && popularContent.results.map((anime) =>(
                <Link  key={anime.id} style={{textDecoration: "none", color: "inherit"}} to={`/anime/${anime.id}`}>
                <DetailCard anime={anime} />
              </Link>
            ))}
        </div>
      </div>
    </div>
  )
}

export default Rightbar