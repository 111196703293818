import "./movie.scss";
import { useContext, useEffect } from "react";
import LocalMoviesRoundedIcon from '@mui/icons-material/LocalMoviesRounded';
import { DarkModeContext } from "../../context/darkModeContext";
import { AnimeContext } from "../../context/animeContent";
import Card from "../../components/Card/Card";
import Header from "../../components/Header/Header";

function Movies() {
  const {darkMode, toggle} = useContext(DarkModeContext);
  const {movieContent, fetchMovies} = useContext(AnimeContext);

  useEffect(()=>{
    async function movieData(){
      await fetchMovies();
    }
    movieData();
  }, [])

  return (
    <div className="movies">
      <Header HIcon={LocalMoviesRoundedIcon} title = 'Movies'/>
        <div className="cardContainer">
          {movieContent && movieContent.results.map((anime)=>(
            <Card key={anime.id} anime={anime} />
          ))}
        </div>
    </div>
  )
}

export default Movies