import "./trending.scss";
import { useContext, useEffect } from "react";
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import { DarkModeContext } from "../../context/darkModeContext";
import { AnimeContext } from "../../context/animeContent";
import Card from "../../components/Card/Card";
import Header from "../../components/Header/Header";

function Trending() {
  const {darkMode, toggle} = useContext(DarkModeContext);
  const {trendingContent, fetchTrendingAnime} = useContext(AnimeContext);

  useEffect(()=>{
    async function trendingData(){
      await fetchTrendingAnime();
    }
    trendingData();
  }, [])

  return (
    <div className="trending">
      <Header HIcon={TrendingUpRoundedIcon} title = 'Trending'/>
        <div className="cardContainer">
          {trendingContent && trendingContent.results.map((anime)=>(
            <Card key={anime.id} anime={anime} />
          ))}
        </div>
    </div>
  )
}

export default Trending