import axios from "axios";
import { createContext, useEffect,  useState } from "react";


export const AnimeContext = createContext();

export const AnimeContextProvider = ({children}) => {
    const url = `https://api.consumet.org/`;

    const [animeContent, setAnimeContent] = useState(null);
    const [trendingContent, setTrendingContent] = useState(null);
    const [movieContent, setmovieContent] = useState(null);
    const [popularContent, setpopularContent] = useState(null);
    const [watchHistory, setwatchHistory] = useState(JSON.parse(localStorage.getItem("watchHistory")) || null);
    const [searchResult, setSearchResult] = useState(null);
    const [searchTerm, setsearchTerm] = useState('');
    const [searchGeneres, setsearchGeneres] = useState([]);
    const [AnimeInfo, setAnimeInfo] = useState(null);
    const [dubSub, setDubSub] = useState(false);

    const fetchHomeAnime = async () =>{
        try {
            if(animeContent === null){
                const animeData = await axios.get(`${url}meta/anilist/recent-episodes?perPage=32`);
                setAnimeContent(animeData.data)
            }
        } catch (error) {
            console.error("Haga BSDK");
        }
    }
    const fetchSearchAnime = async () =>{
        try {
            let searchData = '';
            let url1 = '';
            
            if(searchTerm != "" || searchGeneres.length > 0){
                url1 = `${url}meta/anilist/advanced-search?perPage=32`;
            }else{
                url1 = `${url}meta/anilist/recent-episodes?perPage=32`;
            }
            if(searchTerm){
                url1 += `&query=${searchTerm}`;
            }
            if(searchGeneres.length > 0){
                url1 += `&genres=[${searchGeneres.map(item=>('"'+  item +'"'))}]`;
            }
            console.log(url);
            searchData = await axios.get(url1);
            setSearchResult(searchData.data)
        } catch (error) {
            console.error("Haga BSDK");
        }
    }
    const handleSearch = (inp) =>{
        setsearchTerm(inp);
    }
    const handleGenere = (val) =>{
        let adVal = searchGeneres;
        if(adVal.indexOf(val)  !== -1){
            adVal.splice(adVal.indexOf(val), 1);
        }else{
            adVal.push(val);
        }
        setsearchGeneres([...adVal]);
    }
    const fetchTrendingAnime = async () =>{
        try {
            if(trendingContent === null){
                const trendingData = await axios.get(`${url}meta/anilist/trending?perPage=32`);
                setTrendingContent(trendingData.data)
            }
        } catch (error) {
            console.error("Haga BSDK");
        }
    }
    const fetchPopular = async () =>{
        try {
            if(popularContent === null){
                const popularContentData = await axios.get(`${url}meta/anilist/popular?perPage=8`);
                setpopularContent(popularContentData.data)
            }
        } catch (error) {
            console.error("Haga BSDK");
        }
    }
    const fetchMovies = async () =>{
        try {
            if(movieContent === null){
                const movieData = await axios.get(`${url}meta/anilist/advanced-search?format=MOVIE&perPage=32`);
                setmovieContent(movieData.data)
            }
        } catch (error) {
            console.error("Haga BSDK");
        }
    }

    const fetchAnimeInfo = async(id, dubSub) =>{
        try {
            setAnimeInfo(null);
            if(id !== null){
                const setAnimeInfoData = await axios.get(`${url}meta/anilist/info/${id}?dub=${dubSub}`);
                setAnimeInfo(setAnimeInfoData.data)
            }
        } catch (error) {
            console.error("Haga BSDK");
        }
    }

    const fetchAnimeWatchLink = async (id) =>{
        try{
            const animeWatchData = await axios.get(`${url}meta/anilist/watch/${id}`);
            return animeWatchData.data.sources;
        }catch(e){
            console.error("error");
        }
    }

    const setwatchHistoryData = (anime) =>{
        let data = [];
        if(watchHistory != null){
            data = [anime,...watchHistory.filter(item => item.id !== anime.id)];
        }else{
            data = [anime];
        }
        setwatchHistory(data);
    }
   
    useEffect(()=>{
        localStorage.setItem("watchHistory", JSON.stringify(watchHistory));
    }, [watchHistory])
    useEffect(()=>{
        fetchSearchAnime();
    }, [searchGeneres])
    

    return (
        <AnimeContext.Provider value = {{animeContent, fetchHomeAnime, fetchPopular, popularContent, AnimeInfo, fetchAnimeInfo, fetchAnimeWatchLink, trendingContent, fetchTrendingAnime, movieContent, fetchMovies, setwatchHistoryData, watchHistory, handleSearch, searchTerm, searchResult, fetchSearchAnime, searchGeneres, handleGenere, dubSub, setDubSub }}>{children}</AnimeContext.Provider>
    )
}