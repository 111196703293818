import "./search.scss";
import { useContext, useEffect } from "react";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { DarkModeContext } from "../../context/darkModeContext";
import { AnimeContext } from "../../context/animeContent";
import Card from "../../components/Card/Card";
import Header from "../../components/Header/Header";

function Search() {
  const {darkMode, toggle} = useContext(DarkModeContext);
  const {searchTerm, searchResult, fetchSearchAnime, searchGeneres, handleGenere} = useContext(AnimeContext);

  useEffect(()=>{
    async function searchData(){
      await fetchSearchAnime();
    }
    searchData();
  }, [searchTerm])

  const genres = ["Action","Adventure","Cars","Comedy","Drama","Fantasy","Horror","Mahou Shoujo","Mecha","Music","Mystery","Psychological","Romance","Sci-Fi","Slice of Life","Sports","Supernatural","Thriller"];

  return (
    <div className="search">
      <Header HIcon={SearchRoundedIcon} title = 'Advance Search'/>
       
        <div className="genres">
            <ul >
              { genres && genres.map((item,key)=>(
                <li key={key} className={searchGeneres.indexOf(item) !== -1 ? "active" : ""} onClick={()=> handleGenere(item)}>{item.replace("_", " ")}</li>
                ))}
            </ul>
        </div>
        {searchTerm && <h3 className="searchResult">Search Results</h3>}
        <div className="cardContainer">
          {searchResult && searchResult?.results.map((anime)=>(
            anime.id != "112640" && <Card key={anime.id} anime={anime} />
          ))}
        </div>
    </div>
  )
}

export default Search