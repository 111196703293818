import "./home.scss";
import { useContext, useEffect, useState } from "react";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { AnimeContext } from "../../context/animeContent";
import Card from "../../components/Card/Card";
import Header from "../../components/Header/Header";
function Home() {
  const {animeContent, fetchHomeAnime} = useContext(AnimeContext);

  useEffect(()=>{
    async function HomeData(){
      await fetchHomeAnime();
    }
    HomeData();
  }, [])

  return (
    <div className="home">
        <Header HIcon={HomeRoundedIcon} title = 'Home'/>
        <div className="cardContainer">
          {animeContent && animeContent.results.map((anime)=>(
            <Card key={anime.id} anime={anime} />
          ))}
        </div>
    </div>
  )
}

export default Home