import "./leftbar.scss";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import LocalMoviesRoundedIcon from '@mui/icons-material/LocalMoviesRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import {Link, useLocation} from "react-router-dom";

function Leftbar() {
  const location = useLocation();
  return (
    <header className="leftbar">
        <div className="logo">
          <Link  to="/" style={{textDecoration: "none", color: 'inherit'}}>
            <img src={require("../../assets/logo.png")}  alt="" className="logo" />
          </Link>
        </div>
        <nav>
            <ul>
                <Link to="/" className="link" style={{textDecoration: "none", color: 'inherit'}}><li className={location.pathname == "/" ? "active" : ""}><HomeRoundedIcon /> Home</li></Link>
                <Link to="/trending" className="link" style={{textDecoration: "none", color: 'inherit'}}><li  className={location.pathname == "/trending" ? "active" : ''}><TrendingUpRoundedIcon /> Trending</li></Link>
                <Link to="/movies" className="link" style={{textDecoration: "none", color: 'inherit'}}><li className={location.pathname == "/movies" ? "active" : ''}><LocalMoviesRoundedIcon /> Movie</li></Link>
                <Link to="/search" className="link" style={{textDecoration: "none", color: 'inherit'}}><li className={location.pathname == "/search" ? "active" : ''}><SearchRoundedIcon /> Advance Search</li></Link>
                <Link to="/history" className="link" style={{textDecoration: "none", color: 'inherit'}}><li className={location.pathname == "/history" ? "active" : ''}><AccessTimeFilledRoundedIcon /> Watch History</li></Link>
            </ul>
        </nav>
    </header>
  )
}

export default Leftbar